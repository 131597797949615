export default {
  error_occurred:
    "Lo sentimos, ha ocurrido un error. Vuelva a intentarlo en un momento.",
  login: "Iniciar sesión",
  sign_up: "Crear cuenta",
  register: "Crear cuenta",
  enter_tenant_name: "Seleccione la empresa asociada a tu cuenta de Calendesk.",
  no_account_question: "¿Todavía no tienes una cuenta?",
  do_you_already_have_an_account: "¿Ya eres usuario?",
  login_title: "Iniciar sesión",
  login_tenant: "Nombre de la empresa",
  company_field_placeholder: "p. ej. Hair&More",
  next: "Continuar",
  register_subtitle: "Crear una cuenta de Calendesk",
  register_confirm_title: "Confirma tu dirección de correo electrónico",
  register_confirm_subtitle:
    "Hemos enviado un mensaje con un enlace para activar su cuenta a la dirección de correo electrónico que proporcionó durante el registro.",
  register_confirm_text: "Active su cuenta para obtener acceso a Calendesk.",
  register_data_incorrect:
    "La dirección de correo electrónico que has introducido ya está registrada en el sistema Calendesk. Si has olvidado tu contraseña, por favor usa la opción de recordatorio.",
  resend_activate_email: "Reenviar enlace de activación",
  login_email: "Correo electrónico",
  password: "Contraseña",
  email_or_password_incorrect:
    "La dirección de correo electrónico o la contraseña son incorrectas. Si ha olvidado su contraseña, utilice la opción de recuperar contraseña.",
  please_activate_account:
    "Activar cuenta. Le hemos enviado un enlace  a su dirección de correo electrónico.",
  activate_account_error_message: "El enlace no es válido o ha expirado.",
  forgotten_password: "¿Has olvidado tu contraseña?",
  forgotten_password_title: "Todo el mundo olvida su contraseña a veces",
  forgotten_password_text:
    "Introduzca la dirección de correo electrónico donde enviaremos las instrucciones para cambiar la contraseña.",
  forgotten_password_button: "Solicitar  enlace para cambiar tu contraseña",
  forgotten_password_success_title: "Comprueba tu correo",
  forgotten_password_success_text:
    "Si la dirección de correo electrónico esta registrada en el sistema, siga las instrucciones para cambiar la contraseña.",
  reset_password: "Establecer nueva contraseña",
  reset_password_title: "Establecer nueva contraseña",
  reset_password_text: "Por favor ingrese su nueva contraseña a continuación.",
  reset_password_text_hint:
    "La contraseña debe constar de al menos 8 caracteres, contener una letra mayúscula, un número y un carácter especial.",
  reset_password_button: "Guardar contraseña",
  reset_password_fail_text:
    "No hemos podido restablecer la contraseña. El enlace ha expirado.",
  reset_password_success_title: "La contraseña ha sido cambiada.",
  reset_password_sent_email:
    "Se ha enviado un enlace para establecer una contraseña a la dirección de correo electrónico del usuario.",
  reset_password_success_text: "Inicia sesión con tu nueva contraseña.",
  reset_password_success_button: "OK",
  profile: "Perfil",
  number_of_bookings: "Número de reservas",
  number_of_new_users: "Número de nuevos clientes",
  number_of_newsletter_members: "Número de suscripciones",
  monthly_divided: "Resumen por mes",
  area_code: "Prefijo",
  other: "El restante",
  or: "o",
  short_month_1: "Enero",
  short_month_2: "Febrero",
  short_month_3: "Marzo",
  short_month_4: "Abril",
  short_month_5: "Mayo",
  short_month_6: "Junio",
  short_month_7: "Julio",
  short_month_8: "Agosto",
  short_month_9: "Septiembre",
  short_month_10: "Octubre",
  short_month_11: "Noviembre",
  short_month_12: "Diciembre",
  section_admin_panel: "Panel",
  section_admin_panel_clients: "Panel / Clientes",
  section_admin_panel_newsletters: "Panel / Clientes / Newsletter",
  section_admin_panel_customer_notifications:
    "Panel / Clientes / Notificaciones",
  section_admin_panel_profile: "Panel / Perfil",
  section_admin_panel_employees: "Panel / Empleados",
  section_admin_panel_sales: "Panel / Ventas",
  section_admin_panel_settings: "Panel / Configuración",
  section_admin_panel_service: "Panel / Servicios",
  section_admin_panel_plugins: "Panel / Extras",
  section_admin_panel_payments: "Sección / Facturas y Pagos",
  section_admin_panel_offer: "Panel / Oferta",
  section_admin_panel_tools: "Panel / Herramientas",
  section_admin_panel_notifications: "Panel / Notificaciones",
  simple_store: "Productos",
  section_admin_subscriptions_list: "Panel / Suscripciones",
  last_update: "Última actualización",
  add_client: "Agregar un cliente",
  add_newsletter_record: "Agregar una dirección de correo electrónico",
  upload_photo: "Cargar imagenes",
  upload_photo_error_message:
    "No se ha podido cargar la imagen. El tamaño máximo es de 10 MB.",
  upload_photos_error_message:
    "Es posible que algunas imagenes no se carguen. El tamaño máximo es de 10 MB.",
  get_company_data: "Descargar datos",
  get_company_data_failed: "No se pudieron recuperar los datos.",
  company_name: "Nombre de empresa",
  street_and_number: "Calle y número",
  postcode: "Código postal",
  city: "Ciudad",
  country: "País",
  send_email_with_password_change:
    "¿Enviar un correo electrónico para restablecer una contraseña?",
  client_data: "Datos de los clientes",
  company_business_activity: "Datos de facturación",
  remove_image: "Eliminar foto",
  no_way_to_undo_action: "¡Esta acción no se puede deshacer!",
  remove_image_warning:
    "¿Está seguro de que desea eliminar la foto del usuario?",
  add_booking: "Agregar una reserva",
  add_unavailable_booking_slot: "Añadir un bloqueo",
  add_tag: "Agregar una etiqueta",
  duplicate: "Duplicar",
  add_to_group: "Agregar al grupo",
  cancel_booking: "Cancelar reserva",
  delete_booking: "Cancelar reserva",
  delete_booking_failed: "No se pudo cancelar la reserva seleccionada",
  cancel_booking_failed: "No se pudo cancelar la reserva seleccionada",
  selected_n_positions: "@{amount} elementos seleccionados",
  remove_multiple_users: "Eliminar usuarios",
  remove_multiple_employees: "Eliminar trabajadores",
  remove_employee: "Eliminar un empleado",
  lock_multiple_users: "Bloquear usuarios",
  unlock_multiple_users: "Desbloquear usuarios",
  lock_multiple_employees: "Bloquear empleados",
  unlock_multiple_employees: "Desbloquear trabajadores",
  lock_employee: "Bloquear a un empleado",
  unlock_employee: "Desbloquear a un empleado",
  user_remove_failed_bookings:
    "El usuario tiene visitas programadas o suscripciones activas. Debe cancelarlos antes de eliminarlos.",
  employee_remove_failed_bookings_or_services:
    "El empleado tiene visitas programadas o ya ha sido eliminado. Debes cancelar sus visitas antes de eliminar al empleado.",
  operation_failed:
    "Ocurrió un error al realizar los cambios. Intentar otra vez.",
  accounts_update_succeeded:
    "Las cuentas de usuario seleccionadas se actualizaron correctamente",
  accounts_update_failed: "Error al actualizar las cuentas seleccionadas",
  remove_account_warning:
    "¿Está seguro de que desea eliminar la cuenta de usuario?",
  remove_accounts_warning:
    "¿Está seguro de que desea eliminar las cuentas de usuario seleccionadas?",
  add_group: "Agregar un grupo",
  sort_by: "Clasificar por",
  basic_data: "Datos principales",
  public_data: "Agregar información adicional sobre el empleado",
  remove_category: "Eliminar categoría",
  remove_category_warning: "¿Está seguro de que desea eliminar la categoría?",
  services_in_category: "Servicios en la categoría",
  services_already_assigned_in_category:
    "Los servicios se asignan a la categoría. Asígnelos a una categoría diferente para continuar.",
  assign_service_to_category: "Asignar el servicio a una categoría",
  current_category: "Categoría actual",
  services_assign_failed:
    "Ocurrió un error al asignar los servicios seleccionados a las categorías",
  assign_n_services_to_category: "Asigne @{n} servicios a la categoría:",
  category_order_info:
    "El orden en el que establezca sus categorías y servicios se reflejará en el sitio web y la aplicación móvil de sus clientes.",
  lock_account: "Bloquear la cuenta",
  remove_account: "Eliminar cuenta",
  fetch_data_failed: "Hubo un error al obtener los datos",
  edit_service: "Editar servicios",
  remove_service: "Eliminar servicios",
  variants: "Cambios",
  booking_settings: "Configuración de la reserva",
  booking_time_before: "Reservar ya tu servicio",
  cancel_time_before: "Cancelación del servicio a más tardar",
  change_time_before: "Reprogramación del servicio a más tardar",
  before_visit: "Antes de la visita",
  slots_every_checkbox_text:
    "Muestre a los clientes la oportunidad de reservar cada",
  break_time_before_checkbox_text:
    "Tiempo extra para un descanso antes de la reserva creada",
  break_time_after_checkbox_text:
    "Tiempo extra para un descanso después de crear una reserva",
  resources_info:
    "Seleccione los recursos limitados necesarios en el tiempo de ejecución del servicio. Los clientes no podrán reservar una visita en línea si se agotan los recursos en otras visitas.",
  quantity: "Número",
  quantity_pcs: "Número (uds.)",
  value_is_too_high:
    "El valor introducido es demasiado grande. El valor máximo es: @{number}.",
  value_is_too_low:
    "El valor introducido es demasiado pequeño. El valor mínimo es: @{number}.",
  resource_name: "Nombre del servicio",
  add_variant: "Agregar cambios en el servicio",
  service_active: "Servicio activo en el calendario de los clientes",
  category_active_short: "Activo",
  category_inactive_short: "Inactivo",
  variant_name: "Nombre de sección ",
  service_blocked: "Servicio bloqueado",
  all_employees: "Todos los empleados",
  price_in_currency: "Precio en @{currency}",
  no_employees_found:
    "No se agregaron empleados. Puede crear un empleado ahora y asignarlo a este servicio. Sus clientes podrán seleccionar los empleados agregados asignados a este servicio.",
  add_employee_fail:
    "Hubo un problema al agregar un empleado, intente nuevamente.",
  select_employee_error: "Seleccione al menos un empleado",
  service_update_failed:
    "La actualización del servicio falló. Intentar otra vez.",
  service_creation_failed:
    "No se ha podido cargar servicio. Intentar otra vez.",
  remove_service_warning: "¿Está seguro de que desea eliminar el servicio?",
  remove_service_failed: "No se pudo eliminar el servicio. Intentar otra vez.",
  move: "Mover",
  tag_visits: "Visitas con etiqueta",
  services_with_resource: "Servicios con recurso",
  resource: "Recursos",
  users_in_group: "Grupo de clientes",
  remove_tag_warning:
    "¿Está seguro de que desea eliminar la etiqueta '@ {tag}'?",
  remove_tag: "Eliminar etiqueta",
  edit_tag: "Editar etiqueta",
  remove_resource_warning:
    '¿Está seguro de que desea eliminar el recurso "@{resource}"?',
  remove_resource: "Eliminar recurso",
  edit_resource: "Editar recurso",
  remove_group_warning:
    '¿Está seguro de que desea eliminar el grupo "@{group}"?',
  remove_group: "Eliminar grupo",
  edit_group: "Editar grupo",
  tag_info:
    "Las etiquetas describen las características de la visita del cliente. Cada visita se puede marcar con varias etiquetas.",
  resource_info:
    "Los recursos identifican las instalaciones o equipos necesarios para realizar el servicio. Por ejemplo, silla, habitación, mesa de masaje, etc.",
  resource_info_2:
    "Al asignar recursos a un servicio dado, elimina el riesgo de un problema resultante de la falta de equipo necesario para atender al cliente.",
  group_info:
    "Los grupos son útiles en la gestión de clientes. Cada cliente puede ser asignado a varios grupos.",
  email_notifications: "Notificaciónes de Correo Electrónico",
  sms_notifications: "Notificaciones SMS",
  buy_more: "Comprar más",
  notifications_count: "Número de notificaciones",
  pay: "Pagar",
  notification_first_label: "Disponible",
  notification_second_label_email: "notificaciones de correo electrónico",
  notification_second_label_sms: "notificaciones de SMS",
  notifications_sms_email: "Notificaciones por SMS y correo electrónico",
  notifications_reminder_first:
    "Las notificaciones le recordarán a usted y a sus clientes las próximas reuniones.",
  notifications_reminder_second:
    "Decide a quién informar y con qué tipo de notificación.",
  notification: "Notificación",
  notify_tenant: "Empleado",
  notify_client: "Cliente",
  notification_save_success: "Notificaciones actualizadas con éxito.",
  invoice_data: "Facturación",
  service_categories: "Categorías de servicio",
  application: "Aplicación",
  email: "Correo electrónico",
  currency: "EUR",
  category: "Categoría",
  categories: "Categoría",
  service: "Servicio",
  service_list: "Lista de servicios",
  services: "Servicios",
  employee: "Empleado",
  employees: "Empleados",
  employees_list: "Lista de empleados",
  plugins: "Extras",
  packages: "Tus paquetes",
  plugins_mobile_drafts: "Aplicación móvil",
  plugins_mobile_editor: "Editar aplicación móvil",
  plugins_payments_tpay: "Pagos de TPay",
  plugins_payments_stripe: "Pagos con Stripe",
  settings_platform: "Plataforma",
  notifications: "Notificaciones",
  profile_company: "Tu empresa",
  profile_account: "Tu perfil",
  payments: "Facturas y pagos",
  settings: "Ajustes",
  duration: "Duración",
  from: "Desde",
  to: "A",
  filter: "Filtrar",
  close: "Salir",
  today: "Hoy",
  tomorrow: "Mañana",
  this_week: "Esta semana",
  this_month: "Este mes",
  this_year: "Este año",
  yesterday: "Ayer",
  last_week: "La semana pasada",
  last_month: "El mes pasado",
  last_year: "El año pasado",
  clear: "Borrar",
  service_name: "Nombre del servicio",
  category_name: "Nombre de Categoría",
  price: "Precio",
  name: "Nombre",
  surname: "Apellidos",
  from_cannot_be_greater_than_to:
    "La hora 'desde' no puede ser mayor que la hora 'hasta'",
  name_and_surname: "Nombre y apellidos",
  phone: "Teléfono",
  field_is_required: "Este campo es obligatorio",
  value_is_no_url: "El valor ingresado no es una URL válida.",
  value_is_no_url_to_facebook:
    "El valor ingresado no es una URL de perfil de Facebook válida.",
  value_is_no_url_to_twitter:
    "El valor ingresado no es una URL de perfil de Twitter válida.",
  value_is_no_url_to_instagram:
    "El valor ingresado no es una URL de perfil de Instagram válida.",
  value_is_no_url_to_youtube:
    "El valor ingresado no es una URL de perfil de YouTube válida.",
  value_is_no_domain: "El valor introducido no es válido",
  min_n_characters: "El número mínimo de caracteres es @{min}.",
  max_n_characters: "El número máximo de caracteres es @{max}.",
  email_is_incorrect: "La dirección de correo electrónico es incorrecta.",
  page_not_found: "¡Uups! Página no encontrada :(",
  go_back_to_main_page: "Vuelve a la página de inicio e inténtalo de nuevo.",
  logout: "Cerrar sesión",
  status: "Estado",
  status_approved: "Aceptado",
  status_waiting: "Pendiente",
  status_payment: "A pagar",
  status_cancelled: "Cancelado",
  status_done: "Terminado",
  date: "Datos",
  cancel: "Cancelar",
  yes: "Si",
  no: "No",
  date_from: "Fecha desde",
  date_to: "Fecha desde",
  nothing_found_here: "No hay resultados",
  nothing_here: "Falta",
  save: "Guardar",
  details: "Detalles",
  add_image: "Añadir una imagen",
  change: "Cambiar",
  remove: "Borrar",
  email_is_taken:
    "Ya existe una cuenta con la dirección de correo electrónico introducida.",
  dashboard: "Inicio",
  users: "Clientes",
  users_list: "Lista de clientes",
  calendar: "Calendario",
  add: "Agregar",
  resources: "Recursos",
  translations: "Traducciones",
  web_editor: "Editar página web",
  websites: "Página web",
  mobile_app_title: "Aplicación móvil",
  preview: "Vista previa",
  roles: "Funciones",
  role_admin: "Administrador",
  role_manager: "Gerente",
  role_employee: "Encargado",
  role_employee_basic: "Empleado (limitado)",
  permissions: "Permisos",
  admin_access_question:
    "Un empleado puede iniciar sesión en el panel de Calendesk",
  send_employees_app_link:
    "Envíe un enlace a la aplicación móvil para empleados a su dirección de correo electrónico",
  search: "Buscar",
  add_user: "Agregar un cliente",
  add_employee: "Agregar un empleado",
  add_category: "Agregar categoría",
  edit_category: "Editar categoría",
  add_service: "Agregar un servicio",
  add_resource: "Agregar un recurso",
  bookings_today: "Reservas para hoy",
  bookings_tomorrow: "Reservas para mañana",
  bookings_this_week: "Reservas para esta semana",
  newsletter: "Boletín informativo",
  month_1: "Enero",
  month_2: "Febrero",
  month_3: "Marzo",
  month_4: "Abril",
  month_5: "Mayo",
  month_6: "Junio",
  month_7: "Julio",
  month_8: "Agosto",
  month_9: "Septiembre",
  month_10: "Octubre",
  month_11: "Noviembre",
  month_12: "Diciembre",
  monday: "Lunes",
  tuesday: "Martes",
  wednesday: "Miércoles",
  thursday: "Jueves",
  friday: "Viernes",
  saturday: "Sábado",
  sunday: "Domingo",
  short_day_monday: "Lu",
  short_day_tuesday: "Ma",
  short_day_wednesday: "Mi",
  short_day_thursday: "Ju",
  short_day_friday: "Vi",
  short_day_saturday: "Sá",
  short_day_sunday: "Do",
  refresh: "Actualizar",
  select_date: "Por favor seleccione una fecha",
  edit: "Editar",
  ok: "OK",
  client: "Cliente",
  clients: "Clientes",
  tag: "Etiqueta",
  added_tag: "Se ha añadido una etiqueta.",
  added_group: "Grupo agregado",
  added_resource: "Recurso añadido",
  tags: "Etiquetas",
  phone_is_invalid: "El número de teléfono es incorrecto.",
  birthday: "Cumpleaños",
  send_reset_password:
    "¿Enviar un correo electrónico con un enlace para establecer una contraseña?",
  user_created_successfully: "El cliente fue creado correctamente.",
  employee_created_successfully: "El trabajador fue creado correctamente.",
  description: "Descripción",
  created_calendar_event_correctly: "Entrada de calendario añadida.",
  updated_calendar_event_correctly: "Entrada de calendario actualizada.",
  updated_multiple_bookings_correctly: "Reservas actualizadas.",
  deleted_multiple_bookings_correctly: "Reservas eliminadas.",
  deleted_multiple_unavailable_booking_slots_correctly:
    "Se eliminaron los bloqueos de calendario.",
  deleted_service_event_correctly: "El servicio ha sido eliminado.",
  creating_calendar_event_failed:
    "No se pudo agregar la entrada, inténtelo de nuevo.",
  deleting_service_event_failed:
    "La eliminación falló. Por favor, compruebe que no tiene visitas programadas para este servicio y vuelva a intentarlo.",
  updating_calendar_event_failed:
    "La actualización de la entrada falló, inténtalo de nuevo.",
  updating_bookings_failed:
    "Si no se ha actualizado la reserva, inténtelo de nuevo.",
  deleting_bookings_failed:
    "No se ha podido eliminar la reserva, inténtalo de nuevo.",
  block_spot:
    "Un evento no relacionado con la visita del cliente (bloqueo de calendario para clientes)",
  block: "Bloqueo",
  unavailable_spot: "Bloqueado",
  id: "ID",
  per_page: "Resultados por página",
  groups: "Grupos",
  group: "Grupo",
  delete: "Borrar",
  facebook: "Enlace al perfil en Facebook",
  instagram: "Enlace al perfil de Instagram",
  youtube: "Enlace al perfil en Youtube",
  twitter: "Enlace al perfil de Twitter",
  active_accounts: "Cuentas activas",
  inactive_accounts: "Cuentas inactivas",
  active_account: "Cuenta activa",
  inactive_account: "Cuenta inactiva",
  visit: "Visitar",
  loading: "Cargando ...",
  created_at: "Creado",
  canceled_at: "Cancelado",
  user: "Usuario",
  category_active: "Categoría de servicio visible para los clientes",
  category_inactive: "Categoría de servicios invisibles para los clientes",
  email_verified_at: "Verificacion de email",
  email_unverified: "Correo electrónico no verificado",
  gender: "Género",
  gender_male: "Hombre",
  gender_female: "Mujer",
  gender_other: "Diferente",
  tax: "NIF",
  tax_prefix: "Prefijo",
  block_account: "Bloquear cuenta",
  unblock_account: "Desbloquear cuenta",
  generate_new_password_automatically:
    "Enviar un enlace para cambiar su contraseña a una dirección de correo electrónico.",
  unblock_account_question: "¿Seguro que quieres desbloquear la cuenta?",
  block_account_question: "¿Seguro que quieres bloquear la cuenta?",
  delete_user: "Eliminar cuenta",
  delete_users: "Eliminar cuentas",
  user_can_not_be_loaded: "Ocurrió un error. No se puede cargar el usuario.",
  user_bookings: "Reservas",
  user_newsletter: "Boletín informativo",
  title: "Título",
  color: "Color",
  no_employee_selected_in_calendar:
    "No se seleccionaron empleados. Selecciónelos de la lista anterior para ver el calendario.",
  client_policy_confirmation_text:
    "Por favor confirma que has leído nuestros Términos, Condiciones y Política de Privacidad.",
  /*
  calendar toolbar translations
   */
  listWeek: "Agenda del día",
  resourceTimeGridDay: "Día",
  timeGridWeek: "Semana",
  dayGridMonth: "Mes",
  current_week: "Esta semana",
  current_month: "Este mes",
  /*
  end of calendar toolbar translations
   */
  date_day_from: "Desde (día)",
  date_hour_from: "Desde (horas)",
  date_day_to: "Hasta (día)",
  date_hour_to: "Hasta (horas)",
  created_date_range: "Fecha de creación",
  canceled_date_range: "Fecha de cancelación",
  event_date_range: "Fecha del evento",
  payment_date_range: "Fecha de pago",
  you_can_add_note_here: "Aquí puedes añadir una nota privada",
  account_activation_resend_link_succeeded:
    "El enlace de activación ha sido enviado de nuevo.",
  account_activation_resend_link_failed:
    "Ocurrió un error al generar el enlace, por favor inténtalo de nuevo",
  calendar_settings: "Configuración del calendario",
  minimal_length_of_calendar_event:
    "La duración mínima de un evento de calendario",
  hour_divider_interval: "Marcas de hora visibles cada",
  calendar_time_boundaries: "Opciones de Calendario",
  all_bookings: "Todas las reservas",
  bookings: "Reservas",
  more: "más",
  visit_datetime: "Fecha límite para la reserva",
  client_names: "Nombre y apellidos del cliente",
  waiting: "Pendientes de aprobar",
  payment: "Se requiere un pago por adelantado",
  approved: "Aceptado",
  cancelled: "Cancelado",
  done: "Completado",
  delete_succeeded: "Eliminado correctamente",
  delete_failed: "Ocurrió un error al eliminar",
  confirmation_modal_header: "La acción requiere confirmación.",
  confirmation_modal_warning: "¿Seguro que quieres continuar?",
  change_status: "Cambiar el estado",
  remove_n: "Eliminar (@{n})",
  remove_event: "Eliminar evento",
  editor_tool_global_settings_title: "Configuración global",
  editor_tool_global_settings_subtitle: "Color y logotipo",
  calendesk_file_input_first: "Coloque los archivos aquí o",
  calendesk_file_input_second: "Seleccionar del disco duro",
  calendesk_file_input_third: "Agregar",
  assets: "Archivos",
  domain_configuration_title: "Configuración de dominio",
  domain_configuration_subtitle:
    "Seleccione el dominio bajo el cual su sitio web debe estar disponible",
  domain_configuration_calendesk_domain:
    "Inserte el nombre del subdominio (alias) bajo el cual estará disponible su sitio web, por ejemplo, 'tuempresa.calendsk.com'.",
  domain_configuration_calendesk_domain_2:
    "Inserte el nombre del subdominio (alias) bajo el cual estará disponible su sitio web, por ejemplo, 'tuempresa.calendsk.net'.",
  domain_configuration_own_domain_text:
    "También puede agregar su propio dominio, por ejemplo, 'tusitio.es'.",
  domain_configuration_own_domain_add_button: "Agrega tu propio dominio",
  domain_configuration_own_domain_edit_button: "Edita tu propio dominio",
  domain_configuration_footer: "Su sitio web estará disponible en: @{domain}",
  domain_configuration_form_alias:
    "Subdominio (alias), por ejemplo, el nombre de tu empresa",
  domain_configuration_form_domain: "Dominio",
  add_domain_title: "Agrega tu propio dominio",
  add_domain_verification_error:
    "El dominio está siendo revisado. Si todo está configurado correctamente, espere 15 minutos y vuelva a intentarlo.",
  add_domain_verification_success: "El dominio funciona bien",
  add_domain_first_step_title: "Tu dominio",
  add_domain_first_step_subtitle:
    "Ingrese el nombre de dominio que desea vincular a su sitio web",
  add_domain_first_step_domain: "Tu dominio",
  add_domain_first_step_confirm: "Continuar",
  add_domain_second_step_title: "Panel de proveedores de dominios",
  add_domain_second_step_subtitle:
    "Inicie sesión en el panel de su proveedor de dominio, busque la sección de configuración de DNS y agregue dos nuevos registros, que se detallarán en el siguiente paso.",
  add_domain_second_step_confirm: "Continuar",
  add_domain_third_step_title: "Añadir nuevos registros",
  add_domain_third_step_subtitle:
    "Agregue los registros de dominio como se muestra a continuación. Para algunos operadores de dominio, un registro CNAME puede terminar con un punto.",
  add_domain_third_step_subtitle_dot: "Con un punto al final",
  add_domain_third_step_confirm: "Continuar",
  add_domain_third_step_dns_name: "Nombre",
  add_domain_third_step_dns_type: "Tipo",
  add_domain_third_step_dns_url: "Dirección",
  add_domain_fourth_step_title: "Verificación de operación",
  add_domain_fourth_step_subtitle:
    "Una vez que el dominio esté configurado correctamente, verifique los cambios.",
  add_domain_fourth_step_confirm: "Verificar el funcionamiento del dominio",
  web_global_logo: "Logo",
  web_global_primary_color: "Color principal",
  gallery_input_set_image: "Insertar",
  no_selected_images: "No se han seleccionado fotos.",
  home_about_element_gallery: "Imagen de la Galería",
  new_website: "Nuevo sitio web",
  drafts_table_header_title: "Nombre de la plantilla",
  drafts_table_header_last_updated: "fecha editada",
  published_text: "Publicado",
  not_published_text: "No publicado",
  disabled: "Desactivado",
  publish: "Publicar",
  drafts_category_1: "Belleza",
  drafts_category_2: "Aptitud física",
  drafts_create_website_button: "Crear una página web",
  tool_save_draft: "Guardar borrador",
  drafts_disable_confirm_dialog_title: "Desactivar (web)",
  drafts_disable_confirm_dialog_warning:
    "¿Está seguro de que desea deshabilitar el sitio web?",
  drafts_disable_confirm_dialog_inportant_information:
    "Una vez deshabilitado, su sitio ya no estará disponible en línea.",
  drafts_remove_confirm_dialog_title: "Eliminar borrador",
  drafts_remove_confirm_dialog_warning:
    "¿Está seguro de que desea eliminar el borrador?",
  domain_dialog_title: "la dirección de tu sitio web",
  domain_dialog_button: "Configurar el dominio",
  domain_dialog_domain_text: "Su sitio web se mostrará en:",
  editor_domain_error_message: "El dominio seleccionado no está disponible",
  publish_dialog_button: "Volver a los borradores",
  /*
  ASSETS
   */
  files: "Archivos",
  assets_no_data: "No se agregaron fotos",
  assets_dialog_use_selected_file: "Usar el archivo seleccionado",
  assets_dialog_use_selected_files: "Usar archivos seleccionados",
  remove_asset: "Eliminar archivo",
  remove_asset_warning: "¿Está seguro de que desea eliminar @{file}?",
  change_name_asset_title: 'Cambiar nombre de archivo "@{file}"',
  change_name_asset_new_name: "Nuevo nombre de archivo",
  editor_image: "Editor de imágenes",
  editor_image_confirm_button: "Aplicar",
  editor_image_crop_any: "Ningún",
  editor_image_crop_5_4: "5:4",
  editor_image_crop_7_5: "7:5",
  editor_image_crop_16_9: "16:9",
  editor_image_crop_3_2: "3:2",
  editor_image_crop_square: "Cuadrado",
  editor_image_reset: "Reiniciar",
  editor_image_rotate_left:
    "Gire 90 grados en sentido contrario a las agujas del reloj",
  editor_image_rotate_right:
    "Gire 90 grados en el sentido de las agujas del reloj",
  editor_image_flip_x: "Reflexión en el eje X",
  editor_image_flip_y: "Reflexión sobre el eje Y",
  /*
  Global
   */
  form_is_invalid:
    "Se han detectado errores. Revisa los campos marcados e inténtalo de nuevo.",
  /*
  VALIDATIONS
   */
  no_integer: "Requerir números",
  no_numeric_character: "La contraseña no contiene número.",
  no_lowercase_character: "Sin mayúscula.",
  no_uppercase_character: "Sin mayúscula.",
  no_special_character: "La contraseña no contiene carácter especial.",
  /*
  SETTINGS PLATFORM
   */
  settings_platform_submit_button: "Aplicar cambios",
  c_language_select_label: "Idioma",
  c_currency_select_label: "Moneda",
  c_time_zone_autocomplete_label: "Zona horaria",
  c_language_select_czech: "Checo",
  c_language_select_german: "Alemán",
  c_language_select_english: "Inglés",
  c_language_select_spanish: "Español",
  c_language_select_french: "Francés",
  c_language_select_hindi: "Hindi",
  c_language_select_dutch: "Holandés",
  c_language_select_polish: "Polaco",
  c_language_select_portuguese: "Portugués",
  c_language_select_russian: "Ruso",
  c_language_select_slovak: "Eslovaco",
  c_language_select_ukrainian: "Ucraniano",
  c_language_select_bulgarian: "Bulgaro",
  c_language_select_greek: "Griego",
  c_language_select_estonian: "Estonio",
  c_language_select_finnish: "Finlandés",
  c_language_select_irish: "Irlandés",
  c_language_select_croatian: "Croata",
  c_language_select_hungarian: "Hungaro",
  c_language_select_lithuanian: "Lituano",
  c_language_select_latvian: "Letón",
  c_language_select_maltese: "Maltés",
  c_language_select_slovenian: "Esloveno",
  c_language_select_albanian: "Albanés",
  c_language_select_swedish: "Sueco",
  c_currency_select_pln: "Zloty polaco (PLN)",
  c_currency_select_eur: "Euros (EUR)",
  c_currency_select_usd: "Dólar estadounidense",
  c_currency_select_gbp: "Libra esterlina (GBP)",
  c_currency_select_jpy: "Yen japonés (JPY)",
  c_currency_select_aud: "Dólar australiano (AUD)",
  c_currency_select_cad: "Dólar canadiense (CAD)",
  c_currency_select_chf: "Franco suizo (CHF)",
  c_currency_select_sek: "Corona sueca (SEK)",
  c_currency_select_nzd: "Dólar neozelandés (NZD)",
  c_currency_select_brl: "Real brasileño (BRL)",
  c_currency_select_bgn: "Lev búlgaro (BGN)",
  c_currency_select_czk: "Corona checa (CZK)",
  c_currency_select_dkk: "Corona danesa (DKK)",
  c_currency_select_hkd: "Dólar de Hong Kong (HKD)",
  c_currency_select_huf: "Florín húngaro (HUF)",
  c_currency_select_ils: "Nuevo séquel israelí (ILS)",
  c_currency_select_myr: "Ringgit de Malasia (MYR)",
  c_currency_select_mxn: "Peso mexicano (MXN)",
  c_currency_select_twd: "Nuevo dólar taiwanés (TWD)",
  c_currency_select_nok: "Corona noruega (NOK)",
  c_currency_select_php: "Peso filipino (PHP)",
  c_currency_select_ron: "Leu rumano (RON)",
  c_currency_select_rub: "Rublo ruso (RUB)",
  c_currency_select_sgd: "Dólar de Singapur (SGD)",
  c_currency_select_thb: "Baht tailandés (THB)",
  c_currency_select_uah: "Grivna ucraniana (UAH)",
  c_currency_select_inr: "Rupia india (INR)",
  c_currency_select_clp: "Peso chileno (CLP)",
  c_currency_select_zar: "Rand sudafricano (ZAR)",
  c_currency_select_bif: "Franco burundés (BIF)",
  c_currency_select_djf: "Franco yibutiano (DJF)",
  c_currency_select_gnf: "Franco guineano (GNF)",
  c_currency_select_kmf: "Franco comorense (KMF)",
  c_currency_select_krw: "Won surcoreano (KRW)",
  c_currency_select_mga: "Ariary malgache (MGA)",
  c_currency_select_pyg: "Guaraní paraguayo (PYG)",
  c_currency_select_rwf: "Franco ruandés (RWF)",
  c_currency_select_ugx: "Chelín ugandés (UGX)",
  c_currency_select_vnd: "Dong vietnamita (VND)",
  c_currency_select_vuv: "Vatu vanuatuense (VUV)",
  c_currency_select_xaf: "Franco CFA BEAC (XAF)",
  c_currency_select_xof: "Franco CFA BCEAO (XOF)",
  c_currency_select_xpf: "Franco CFP (XPF)",
  /*
  PROFILE COMPANY
   */
  profile_company_name_label_input: "Nombre de empresa",
  profile_company_name_placeholder_input: "p. ej. Hair&More",
  profile_company_contact_header: "Información de contacto",
  profile_company_contact_address_label_autocomplete: "Dirección",
  profile_company_contact_email_label_input: "Dirección de correo electrónico",
  profile_company_contact_country_autocomplete: "País ",
  profile_company_work_hours_header: "Días y horas de trabajo",
  profile_company_social_media_header: "Redes sociales",
  profile_company_social_media_facebook_label_input:
    "Enlace al perfil en Facebook",
  profile_company_social_media_twitter_label_input:
    "Enlace al perfil de Twitter",
  profile_company_social_media_instagram_label_input:
    "Enlace al perfil de Instagram",
  profile_company_social_media_youtube_label_input:
    "Enlace al perfil en Youtube",
  profile_company_regulations_header: "Estatuto",
  profile_company_regulations_label_textarea: "Estatuto",
  profile_company_privacy_policy_header: "Política de privacidad",
  profile_company_privacy_policy_label_textarea: "Política de privacidad",
  profile_company_contact_street_label_input: "Calle y número",
  profile_company_contact_zip_code_label_input: "Código postal",
  profile_company_contact_city_label_input: "Ciudad",
  plugins_store_all_plugins_tab: "Todos los complementos",
  plugins_mobile_drafts_header_button: "Editar  aplicación móvil",
  create_mobile_draft_dialog_title: "Seleccionar plantilla",
  create_mobile_draft_dialog_name_label_input: "Nombre de la plantilla",
  create_mobile_draft_dialog_name_label_input_placeholder:
    "Por ejemplo, mi aplicación",
  create_mobile_draft_dialog_select_category: "Categoría",
  create_mobile_draft_dialog_submit: "Guardar y continuar",
  plugins_mobile_drafts_new_application_button: "Nueva aplicación móvil",
  plugins_mobile_drafts_disable_confirm_dialog_title:
    "Desactivar aplicación móvil",
  plugins_mobile_drafts_disable_confirm_dialog_warning:
    "¿Seguro que quieres desactivar la aplicación móvil?",
  plugins_mobile_drafts_disable_confirm_dialog_important_information:
    "Una vez desactivada, su aplicación no estará disponible en línea.",
  plugins_mobile_drafts_remove_confirm_dialog_title: "Eliminar borrador",
  plugins_mobile_drafts_remove_confirm_dialog_warning:
    "¿Está seguro de que desea eliminar el borrador?",
  plugins_mobile_preview_information_title:
    "Vista previa de los cambios en el teléfono",
  plugins_mobile_preview_information_paragraph_1:
    "Publicar la plantilla. Descargue la aplicación Calendesk e ingrese un nombre en la primera pantalla",
  plugins_mobile_preview_information_paragraph_2:
    "Como identificador de la empresa. Luego inicie sesión en su cuenta de usuario, proporcionando su dirección de correo electrónico y contraseña.",
  plugins_mobile_preview_information_paragraph_3:
    "Después de iniciar sesión, la aplicación se actualizará automáticamente con los cambios realizados",
  plugins_mobile_preview_text_button: "Enviar un enlace a la aplicación",
  send_mobile_app_link_dialog_title: "Enviar un enlace a la aplicación",
  send_mobile_app_link_dialog_email_tab_title: "Correo electrónico",
  send_mobile_app_link_dialog_email_tab_input_label:
    "Dirección de correo electrónico a la que debemos enviar el enlace a la aplicación",
  send_mobile_app_link_dialog_email_tab_submit_button:
    "Enviar correo electrónico",
  send_mobile_app_link_dialog_sms_tab_title: "SMS",
  send_mobile_app_link_dialog_sms_tab_input_label:
    "Número de teléfono al que vamos a enviar el enlace a la aplicación",
  send_mobile_app_link_dialog_sms_tab_submit_button: "Enviar SMS",
  publish_mobile_draft_dialog_title:
    "La aplicación móvil está siendo publicada.",
  publish_mobile_draft_dialog_subtitle:
    "La aplicación puede tardar unos minutos en aparecer en la web.",
  publish_mobile_draft_dialog_button: "Volver a los borradores",
  mobile_home_form_title: "Vista general",
  mobile_home_form_logo_label: "Imagen de fondo",
  mobile_home_form_logo_select_button: "Elegir",
  mobile_home_form_slogan_label: "Bóveda",
  mobile_home_form_signup_button: "Botón de registro",
  mobile_services_form_title: "Servicios",
  mobile_services_form_menu_services_label: "Pestaña",
  mobile_services_form_services_title: "Bóveda",
  mobile_services_form_services_input_label: "Buscador",
  mobile_services_form_services_search_empty_screen_subtitle_label:
    "No hay resultados",
  mobile_services_form_services_types_title_label: "Variantes de servicio",
  mobile_services_form_cancel_booking_success_button_label: "Botón Otros",
  mobile_services_form_logo_label: "Imagen de fondo",
  mobile_services_form_logo_select_button: "Elegir",
  mobile_about_us_form_title: "Entonces como",
  mobile_about_us_form_location_title: "Encabezado de ubicación",
  mobile_about_us_form_location_info:
    "Puede configurar la dirección en el panel principal de Calendesk en Configuración / Tu empresa. Eliminar la dirección también la ocultará en la aplicación.",
  mobile_about_us_form_location_2_info:
    "Complete la dirección en el panel principal de Calendesk en Configuración / Tu empresa para mostrar la ubicación de su empresa en esta sección.",
  mobile_about_us_form_menu_about_us_label: "Pestaña",
  mobile_about_us_form_about_us_about_us_title_label:
    "Encabezado para descripción",
  mobile_about_us_form_contact_info:
    "Puede configurar el número de teléfono y el horario de apertura en el panel de Calendesk en Configuración / Tu empresa. Si desea ocultar las secciones anteriores, elimine su contenido.",
  mobile_about_us_form_employee_info:
    "Los empleados visibles en esta sección se pueden configurar en el panel de Calendesk en la pestaña Empleados.",
  mobile_about_us_form_bookings_info:
    "En la vista previa de la derecha puedes ver reservas de ejemplo.",
  mobile_about_us_form_about_us_employees_title_label:
    "Titular para especialistas",
  mobile_about_us_form_about_us_contact_title_label:
    "Encabezado para el contacto",
  mobile_about_us_form_about_us_text_label: "Texto",
  mobile_about_us_form_logo_label: "Imagen de fondo",
  mobile_about_us_form_logo_select_button: "Elegir",
  mobile_bookings_form_title: "Reservas",
  mobile_bookings_form_menu_bookings_label: "Pestaña",
  mobile_bookings_form_menu_active_bookings_label: "Reservas activas",
  mobile_bookings_form_menu_previous_bookings_label: "Reservas anteriores",
  mobile_account_form_title: "Perfil",
  mobile_account_form_menu_bookings_label: "Pestaña",
  mobile_account_editor_info_message:
    "Seleccione la sección a continuación que desea editar. Puede descargar la aplicación móvil para clientes desde @{google} o @{apple} y ver el resultado tan pronto como se publique.",
  mobile_account_editor_info_message_2:
    "Después de descargar la aplicación, ingrese el nombre de tu empresa (@{company}) en la primera pantalla.",
  mobile_account_editor_home_image_message:
    "El tamaño sugerido de la imagen principal es de 600x800 píxeles.",
  mobile_account_editor_global_logo_image_message:
    "El tamaño sugerido del logotipo es de 600x300 píxeles.",
  // Payments
  add_payment_method_dialog_subtitle:
    "Ingrese los detalles de la tarjeta que usará para pagar con Calendesk. Calendesk no almacena los datos de su tarjeta, los datos se transmiten de forma segura mediante el sistema Stripe del operador de pago.",
  card_number: "Número de tarjeta",
  date_of_expiry: "Fecha de Expiración",
  ccv: "CCV",
  card_stored_correctly: "Los datos se han guardado correctamente.",
  enter_card_data_error: "Introduzca los datos de la tarjeta correctos.",
  // Packages and plans
  plans: "Planes",
  activate_plan: "activa el plan",
  current_plan: "Plan actual",
  plan_valid_till: "válido hasta",
  plans_headline: "Precios flexibles a la medida de tu negocio",
  plans_headline_2:
    "¡Agilice el proceso de reserva y desarrolle su negocio gracias a soluciones modernas!",
  monthly_payment: "Pago mensual",
  annual_payment: "Pago anual",
  for_free_forever: "gratis para siempre",
  monthly_no_vat: "mensual",
  yearly_no_vat: "al año",
  once_no_vat: "una vez",
  currently_used: "usted está utilizando actualmente",
  cancel_plan_generic_title:
    "Antes de tomar una decisión, recuerda que siempre estamos aquí para ti! 💬",
  cancel_plan_generic_info:
    "Chatea con nosotros para encontrar la solución más beneficiosa para ti 🤝 u organiza una consulta en línea gratuita con nosotros, durante la cual responderemos a todas tus preguntas.",
  cancel_plan: "Confirmo la cancelación 😢",
  cancel_plan_1: "Yo y mis clientes perderemos acceso al sistema de reservas.",
  cancel_plan_2: "Cancelar plan",
  lower_plan: "Cambiar a un plan inferior",
  cancel_subscription_title:
    "¿Estás seguro de que quieres cancelar la suscripción? 😔",
  cancel_subscription_title_2:
    "Actualmente estás utilizando un período de prueba gratuito. Tu plan expirará automáticamente si no finalizas la compra.",
  cancel_subscription_subtitle_2: "¡No, me quedo! 😊",
  cancel_subscription_subtitle_3:
    "No puede cancelar el plan durante el período de prueba.",
  cancel_subscription_text: "Su suscripción finalizará el",
  cancel_subscription_text_2:
    "Todas las funciones pagas seguirán estando disponibles para su uso hasta el final del período de suscripción.",
  cancel_subscription_text_3: "En lugar de cancelar, elijo un plan más bajo.",
  cancel_subscription_text_4: "Plan @{plan} por solo @{price} (sin IVA).",
  plans_advantage_1: "Número ilimitado de empleados",
  plans_advantage_2: "Panel de administración",
  plans_advantage_3: "Sistema de reservas y calendario",
  plans_advantage_4: "Número ilimitado de clientes",
  plans_advantage_5: "Historial de visitas",
  plans_advantage_6: "Recursos asociados a las reservas",
  plans_advantage_7: "horario de trabajo",
  plans_advantage_8: "Pagos en línea en la etapa de reserva",
  plans_advantage_9: "Notificaciónes de Correo Electrónico",
  plans_advantage_10: "Paquete SMS para empezar - 50 piezas",
  plans_advantage_11: "Paquete de SMS - 100 piezas por mes",
  plans_advantage_12: "Paquete de SMS - 200 piezas por mes",
  plans_advantage_13: "Asistencia técnica",
  plans_advantage_14: "Sitio web (subdominio Calendesk)",
  plans_advantage_15: "Aplicación móvil para empleados.",
  plans_advantage_16: "Aplicación móvil para clientes.",
  plans_advantage_17: "Aplicación móvil independiente para clientes",
  plans_advantage_18: "Supervisor de empresa dedicado",
  plans_advantage_19: "Política de privacidad y normativa",
  plans_advantage_20: "Sitio web (dominio propio)",
  plans_advantage_21: "Certificado SSL para el sitio web",
  plans_advantage_22: "1 empleado",
  plans_advantage_23: "Hasta 5 empleados",
  plans_advantage_24: "Hasta 250 clientes",
  order_plan_summary_title: "Resumen del pedido",
  monthly_subscription: "Suscripción mensual",
  yearly_subscription: "Suscripción anual",
  plan: "Plan",
  summary_payment: "pago",
  summary: "Juntos",
  promo_code: "un código de descuento",
  promo_code_is_invalid: "El código de reembolso ha caducado o no es válido",
  promo_code_is_valid: "El código de descuento se ha añadido al pedido.",
  continue: "Continuar",
  continue_and_pay: "Continuar y pagar",
  payment_method_deleted_successfully: "El método de pago ha sido eliminado.",
  payment_method_delete_failed:
    "No se ha eliminado el método de pago, inténtalo de nuevo.",
  subscription_set_successfully: "Suscripción configurada correctamente.",
  subscription_cancelled_successfully: "La suscripción ha sido cancelada :(.",
  // Invoices
  payment_method: "Método de pago",
  add_payment_method: "Añade un metodo de pago",
  change_payment_method: "Cambia tu método de pago",
  delete_payment_method: "Borrar",
  my_invoices: "Mis facturas",
  payment_history: "Historial de pagos",
  download_pdf: "Descargar PDF",
  invoice_data_updated_successfully:
    "Los detalles de la factura se han actualizado correctamente",
  invoice_data_update_failed:
    "Los detalles de la factura no se han actualizado, inténtelo de nuevo.",
  tenant: "Negocio",
  created: "Confirmación",
  deleted: "Eliminación",
  updated: "Cambio",
  reminder: "Recordatorio",
  invoice_number: "número de factura",
  invoice_issued_at: "fecha de emisión",
  invoice_total: "Suma",
  invoice_vat: "IVA",
  invoice_description: "Descripción",
  buy_package_success:
    "El paquete ha sido comprado. La activación puede tardar unos segundos.",
  buy_package_failed:
    "Hubo un problema con su compra. Asegúrese de que su tarjeta funcione y acepte pagos.",
  buy_package_confirmation_button: "Compra el paquete",
  buy_package_confirmation_question:
    "¿Estás seguro de que quieres comprar el paquete? Su tarjeta será cargada.",
  incorrect_time: "hora incorrecta",
  web_editor_color_1_label: "El color del botón de confirmación.",
  web_editor_color_2_label: "Filtrar",
  web_editor_color_3_label: "El color del botón cancelar.",
  web_editor_color_4_label: "Color de la animación de carga.",
  web_editor_color_5_label: "Color de fondo",
  web_editor_color_6_label: "El color del texto",
  web_editor_color_7_label: "Color de texto más claro",
  web_editor_color_8_label: "Color de fondo",
  web_editor_color_9_label: "El color de los enlaces.",
  web_editor_color_10_label: "Color de los acentos",
  web_editor_color_11_label: "Color de fondo",
  web_editor_color_12_label: "Color de fondo",
  web_editor_color_13_label: "color de la cremallera",
  web_editor_color_14_label: "Color de fondo",
  web_editor_color_15_label: "El color del texto",
  web_editor_color_16_label: "Color de fondo",
  web_editor_color_17_label: "Color del icono",
  web_editor_color_18_label: "El color de los enlaces.",
  contact_data_info:
    "Los datos de contacto facilitarán que tus clientes encuentren tu empresa si, por ejemplo, utilizas una aplicación móvil.",
  service_info_7:
    "Si activa el prepago, sus clientes deberán realizar un pago antes de que se acepte la reserva. Hasta entonces, bloquearemos el calendario para permitir que los clientes completen su pago. Las reservas no pagadas se eliminarán en el momento que establezca a continuación. El calendario se desbloqueará después de eliminar las reservas no pagadas.",
  awaiting_booking_payment_time: "Tiempo para pagar la reserva",
  service_description:
    "Se mostrará una breve descripción del servicio al seleccionar el servicio.",
  company_data_saved: "Los datos de tu empresa se han guardado correctamente",
  company_data_failed:
    "Lo sentimos, ha ocurrido un error. Los datos de tu empresa no se han guardado, inténtelo de nuevo.",
  closed: "Cerrado",
  not_working: "No funciona",
  generate_regulations: "Generar",
  generate_regulations_success:
    "Los Términos y Condiciones y la Política de Privacidad han sido generados con éxito",
  generate_regulations_fail:
    "Hubo un problema al generar los términos y la política de privacidad, intente nuevamente.",
  generate_regulations_info:
    "Genera términos de servicio y política de privacidad que pueden ser utilizados en tu sitio web de Calendesk. Estos documentos se crean para empresas que gestionan reservas en línea y procesan pagos. Ingresa los detalles completos de tu empresa y haz clic en 'Generar'. Añade los documentos a tu sitio web usando el constructor, seleccionando secciones de la categoría 'Otras'. Recuerda, los documentos generados son solo muestras y la versión final debe ser consultada con un abogado. Calendesk no es responsable del contenido de tus documentos.",
  regulations_and_terms_title: "Generador de documentos",
  full_company_name: "El nombre completo de tu empresa.",
  full_company_name_example: "p.ej. Pro&More SL",
  full_company_address: "La dirección de registro de tu empresa",
  full_company_address_example: "p.ej. C. Gran Vía 1, 28013 Madrid",
  regulations_data_services_1_label:
    "¿Para qué servicios estás creando regulaciones?",
  regulations_data_services_1_example:
    "p.ej. legales, psicológicos, cosméticos",
  regulations_data_services_2_label: "¿Qué servicios proporcionas?",
  regulations_data_services_2_example:
    "p.ej. legales, psicológicos, cosméticos",
  regulations_data_company_url_label:
    "Dirección URL del sitio web o calendario",
  regulations_data_company_email_label: "Email de contacto para la empresa",
  regulations_data_company_email_example: "p.ej. contacto@misitio.es",
  regulations_data_company_phone_label: "Teléfono de contacto para la empresa",
  regulations_data_company_phone_example: "p.ej. +34666111222",
  regulations_data_date_label: "Las regulaciones se aplican a partir del día",
  example_short: "p.ej.",
  privacy_policy_description_too_long:
    "El texto de la política de privacidad es demasiado largo (200,000 caracteres).",
  regulations_description_too_long:
    "El texto de la regulación es demasiado largo (200,000 caracteres).",
  copy_data: "Copia los datos",
  no_employees_info:
    "Aún no tiene empleados, haga clic a continuación para agregar el primero.",
  no_users_info:
    "Aún no tiene ningún cliente, haga clic a continuación para agregar el primero.",
  no_bookings_info:
    "Aún no tienes una reserva, haga clic a continuación para agregar la primera.",
  no_services_info:
    "Aún no tiene los servicios, haga clic a continuación para agregar el primero.",
  no_categories_info:
    "Aún no tiene una categoría, haga clic a continuación para agregar la primera.",
  no_newsletter_info:
    "Aún no tiene direcciones de correo electrónico en el boletín, haga clic a continuación para agregar la primera.",
  employee_position: "Posición",
  employee_position_example: "notario público. Gerente",
  employee_note_description:
    "Aquí puede presumir a su empleado, mostraremos esta información en el sitio web y en la aplicación móvil.",
  employee_permission_info:
    "Puede otorgar permisos a sus empleados y permitirles iniciar sesión en el sistema.",
  employee_permission_employee: " Encargado (de confianza)",
  employee_permission_employee_basic: "Empleado (limitado)",
  employee_permission_manager: "Gerente",
  employee_permission_admin: "Administrador",
  employee_permission_employee_info:
    "Los empleados con este permiso tienen vista completa del calendario de otros empleados. Pueden cambiar libremente las reservas entre otros empleados. Tienen acceso completo a las cuentas de todos los clientes y su reserva y pago. No tienen acceso para administrar las cuentas de otros empleados. No pueden editar el sitio web y la aplicación móvil. No pueden cambiar la configuración principal del sistema.",
  employee_permission_employee_basic_info:
    "Los empleados con esta autorización solo pueden ver su calendario de reservas. Pueden ver, agregar, editar y eliminar citas solo dentro de su cuenta. Pueden buscar clientes mientras agregan una reserva y ver sus detalles básicos. No pueden eliminar ni editar los datos del cliente. No tienen acceso a los datos de otros empleados y sus reservas.",
  employee_permission_manager_info:
    "Las cuentas con este permiso tienen permisos como Empleado (de confianza). Además, pueden administrar otros empleados, servicios, categorías, sitio web y aplicación móvil. No pueden administrar el plan Calendesk y paquetes de servicios adicionales, no tienen acceso a la integración con el sistema de pago.",
  employee_permission_admin_info:
    "Las cuentas de administrador tienen pleno derecho a todas las funciones del sitio web.",
  choose_role: "Seleccione una función",
  employee_app_info:
    "Sus empleados pueden ver el calendario de clientes en una aplicación móvil especialmente preparada disponible para iOS y Android. Marque si desea que le enviemos un correo electrónico con un enlace para descargar la aplicación.",
  employee_public_checkbox:
    "El empleado es visible para los clientes y acepta reservas a través del calendario en línea",
  employee_public_info:
    "Puede completar información adicional (opcional) sobre el empleado. Estos datos pueden utilizarse posteriormente en secciones especiales, por ejemplo, en el sitio web o en la aplicación móvil.",
  employee_working_hours: "Calendario de disponibilidad",
  employee_working_hours_info:
    "El siguiente cuadro de disponibilidad se utilizará para los servicios que se benefician de la disponibilidad de los empleados.",
  color_in_calendar: "Colorea el calendario",
  delete_success: "Eliminado correctamente.",
  update_success: "Guardado correctamente.",
  web_editor_color_19_label: "El color del texto",
  skip: "Saltar",
  configuration: "Configuración",
  start_configuration: "Empecemos",
  fill: "Completar",
  filled: "Lleno",
  create: "Crear",
  enable: "Activar",
  disable: "Apagar",
  mobile_home_form_go_to_login_label: "Ir a iniciar sesión",
  accept_terms_and_conditions_1: "Al registrarme, acepto",
  accept_terms_and_conditions_2: "Términos de Uso",
  accept_terms_and_conditions_3: "y confirmo que he leído",
  accept_terms_and_conditions_4: "Política de privacidad.",
  change_currency: "Cambiar moneda",
  change_currency_info: "Cambio de moneda para todos los servicios.",
  web_editor_color_20_label: "color del eslogan",
  copy: "Copiar",
  copied_to_clipboard: "Copiado al portapapeles",
  stripe: "Stripe",
  stripe_description:
    "Operador de pago avanzado que te permitirá procesar los pagos de los clientes en tiempo real.",
  go_to_stripe: "Ir a Stripe",
  stripe_info_step_1:
    "Haga clic en 'Crear cuenta de Stripe' para ir a Stripe y crear una cuenta. Al finalizar, su cuenta de pago se vinculará a Calendesk. Calendesk no tendrá acceso a su cuenta. Stripe es un operador de pago independiente. Vincular su cuenta de Stripe a Calendesk le permitirá crear pagos en su nombre, en su sitio web y en la aplicación móvil.",
  stripe_info_step_2:
    "Entra en Stripe para completar el proceso de registro de la cuenta.",
  stripe_info_step_3:
    "La creación de la cuenta en Stripe está completa. Stripe comprobará la información enviada.",
  stripe_info_step_4:
    "Tu cuenta de Stripe está conectada correctamente. Puede aceptar pagos de los clientes. Recuerda seleccionar la opción 'Prepago' para servicios que requieran prepago.",
  stripe_title_step_1: "Crear una cuenta de Stripe",
  stripe_title_step_2: "Creación de cuenta en curso",
  stripe_title_step_3: "Verificación de la cuenta",
  stripe_title_step_4: "Cuenta conectada",
  allow_online_payments: "Pago",
  allow_online_payment_2: "Habilitar los pagos en línea para este servicio.",
  payment_required: "Pago por adelantado",
  payment_required_2:
    "Solicitar anticipo de pago antes de confirmar la reserva.",
  service_payments: "Configuración de pago",
  paid_online: "pago",
  paid: "Pagado",
  not_paid: "No pagado",
  navbar_element_form_home_label: "Inicio",
  user_payments: "Pago",
  past_due_subscription_title: "Tu cuenta ha sido bloqueada temporalmente 😵",
  past_due_subscription_subtitle_1:
    "Lamentamos informarte que tu cuenta en Calendesk ha sido temporalmente bloqueada debido a retrasos en los pagos. Hemos intentado varias veces descontar fondos de tu tarjeta, pero sin éxito. Por lo tanto, te recomendamos que verifiques tu tarjeta e intentes realizar el pago de nuevo.",
  past_due_subscription_subtitle_2:
    "Si deseas cambiar tu tarjeta, puedes hacerlo a continuación. Una vez que se haya realizado el pago, tu cuenta se desbloqueará automáticamente. Si tienes alguna pregunta o inquietud, no dudes en contactarnos a través del chat. Estaremos encantados de aclarar tus dudas y ayudarte a resolver problemas con los pagos.",
  past_due_subscription_subtitle_3:
    "Lamentamos informarte que tu cuenta en Calendesk ha sido temporalmente bloqueada debido a retrasos en los pagos. Por favor, ponte en contacto de manera urgente con el administrador de la cuenta o con la persona encargada de los pagos.",
  retry_past_due_invoice_title: "Reintentar cargo",
  past_due_subscription_title_2: "Hay un pago pendiente en tu cuenta",
  past_due_subscription_subtitle_4:
    "Por favor, realiza el pago para evitar el bloqueo automático de la cuenta.",
  past_due_subscription_subtitle_5:
    "Mientras haya pagos pendientes en tu cuenta, no es posible cambiar o cancelar las suscripciones.",
  past_due_subscription_subtitle_6:
    "Si quieres reemplazar la tarjeta, puedes hacerlo a continuación. Si tienes alguna pregunta o duda, no dudes en contactarnos a través del chat. Estaremos encantados de disipar tus dudas y ayudarte a resolver el problema de pago.",
  select_plan: "Elige un plan",
  subscription_will_not_renew_info: "Tu plan no se renovará automáticamente",
  no_email_for_password_reset_info:
    "No puedes establecer una contraseña si el usuario seleccionado no ha proporcionado una dirección de correo electrónico.",
};
